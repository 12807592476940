<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">แก้ไขข้อมูลบริหารโครงการ</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ปีงบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="fiscalYear"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="fiscalYear"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ชื่อโครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="projectName"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="projectName"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>สถานะการดำเนินการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="projectStatus"
                                rules="required"
                            >
                                <v-select
                                    :items="projectStatusItems"
                                    v-model="projectStatus"
                                    item-text="projectStatusName"
                                    item-value="projectStatusName"
                                    dense
                                    :error-messages="errors"
                                    outlined
                                    clearable
                                ></v-select>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>กิจกรรม</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="event"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="event"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>จำนวนงบประมาณ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="budget"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="budget"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ระยะเวลาดำเนินการ</v-subheader>
                        </v-col>
                        <v-col cols="3">
                            <v-menu
                                v-model="menu"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="startProject"
                                        label="วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="startProject"
                                    locale="th-th"
                                    @input="menu = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                        <v-col cols="1">
                            <v-subheader>ถึงวันที่</v-subheader>
                        </v-col>
                        <v-col cols="3">
                            <v-menu
                                v-model="menu2"
                                :close-on-content-click="false"
                                :nudge-right="40"
                                transition="scale-transition"
                                offset-y
                                min-width="290px"
                            >
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="endProject"
                                        label="วันที่"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                </template>
                                <v-date-picker
                                    v-model="endProject"
                                    locale="th-th"
                                    @input="menu2 = false"
                                ></v-date-picker>
                            </v-menu>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ผู้รับผิดชอบ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="responsiblePerson"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="responsiblePerson"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ลิ้งค์ไฟล์โครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="linkProjectFile"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="linkProjectFile"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ลิ้งค์ไฟล์คณะกรรมการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="linkBoardFile"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="linkBoardFile"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">
                            <v-subheader>ลิ้งค์ไฟล์สรุปโครงการ</v-subheader>
                        </v-col>
                        <v-col cols="5">
                            <validation-provider
                                v-slot="{ errors }"
                                name="linkProjectSummaryFile"
                                rules="required"
                            >
                                <v-text-field
                                    v-model="linkProjectSummaryFile"
                                    outlined
                                    dense
                                    :error-messages="errors"
                                    required
                                ></v-text-field>
                            </validation-provider>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    <v-row>
                        <v-col>
                            <v-btn depressed color="success" @click="save()">
                                บันทึก
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['projectId'],
    data: () => ({
        fiscalYear: "",
        projectName: "",
        budget: 0,
        startProject: new Date().toISOString().substr(0, 10),
        endProject: new Date().toISOString().substr(0, 10),
        responsiblePerson: "",
        linkBoardFile: "",
        linkProjectFile: "",
        linkProjectSummaryFile: "",
        menu: false,
        menu2: false,
        formChkErrors: false,
        projectStatusItems: [
            "ยังไม่ดำเนินการ",
            "อยู่ระหว่างดำเนินการ",
            "ดำเนินการแล้ว"
        ],
        projectStatus: null,
        event: ""
    }),
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getData()
      this.$store.commit('setLoading', false)
    },
    methods: {
        async getData() {
            try {
                const res = (await Api().get('project/' + this.projectId)).data;

                console.log(res.data)

                if (res.status == 'success') {
                    this.fiscalYear = res.data.fiscal_year;
                    this.projectName = res.data.project_name;
                    this.budget = res.data.budget;
                    this.projectStatus = res.data.project_status;
                    this.event = res.data.event;
                    this.startProject = res.data.start_project;
                    this.endProject = res.data.end_project;
                    this.responsiblePerson = res.data.responsible_person;
                    this.linkBoardFile = res.data.link_board_file
                    this.linkProjectFile = res.data.link_project_file
                    this.linkProjectSummaryFile = res.data.link_project_summaryFile

                } else {
                    console.log(res.data);
                }

            } catch (err) {
                console.log(err.message);
            }
        },
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                fiscal_year: this.fiscalYear,
                project_name: this.projectName,
                budget: this.budget,
                project_status: this.projectStatus,
                event: this.event,
                start_project: this.startProject,
                end_project: this.endProject,
                responsible_person: this.responsiblePerson,
                link_board_file: this.linkBoardFile,
                link_project_file: this.linkProjectFile,
                link_project_summaryFile: this.linkProjectSummaryFile,
            }

            this.formChkErrors = false
            this.formChkErrors = this.$refs.form.validate()

            if(this.formChkErrors){
                let res = (await Api().put("project/" + this.projectId, req)).data;

                // console.log('data', res)

                if (res.status == 'success') {
                    this.$store.commit('setLoading', false)
                    await this.$refs.confirm.open('ข้อความ', 'แก้ไขข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                    this.$router.push('/project')
                }else{
                    this.$store.commit('setLoading', false)
                }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>